import './App.css';
import Landungpage from './components/landingpage';
import Aboutus from './components/aboutus';
import Contactus from './components/contactus';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './components/footer';
import Nav from './components/nav';
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <div className="App">
      <Nav/>
<Landungpage/>
<Aboutus/>
<Contactus/>
<Footer/>
    </div>
    </ThemeProvider>
  );
}

export default App;
