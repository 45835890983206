import React from 'react';
import bgm_animated  from '../assets/bgm10.png';
import  IconButton from '@mui/material/IconButton';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';


function Footer() {
  return (
    <div ><div style={{display:"flex",flexDirection:"column",width:"100%",backgroundColor:"#111"}}>
        <div className='foot' style={{display:"flex",width:"85%",margin:"auto",justifyContent:"space-around",alignItems:"center",borderBottom:".5px solid #8f949f",flexDirection:"row",flexWrap:"nowrap"}}>
        <div style={{width:"100%",justifyContent:"center",display:"flex"}}>
        <img style={{height:"100px",objectFit:"cover",padding:"10px"}} src={bgm_animated}/>
            </div>  

       
            <div style={{width:"100%",justifyContent:"center",display:"flex"}}>
            <div className='foot2' style={{display:"flex",flexDirection:"column",padding:"20px",alignItems:"start",}}><span style={{color:"#8f949f"}}>Contact with us</span>
            <div style={{marginTop:"10px",display:"flex",alignItems:"center"}}>
            <IconButton className='flair-badge-wrapper' aria-label="remove"    size="small" >

<CallIcon  aria-hidden="true"></CallIcon>
</IconButton><span >+91-9188878022</span>
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
            <IconButton className='flair-badge-wrapper' aria-label="remove"    size="small" >

<EmailIcon  aria-hidden="true"></EmailIcon>
</IconButton>   <span>support@agnex.in</span>
            </div>
      </div>
            </div>
            <div style={{width:"100%",justifyContent:"center",display:"flex"}}>
            <div  className='foot2' style={{display:"flex",flexDirection:"column",padding:"20px",alignItems:"start"}}><span style={{color:"#8f949f"}}>Address</span><span style={{marginTop:"10px",textAlign:"start"}}>Agnex Technologies, Kollamkudy Tower<br></br>
            Kollamkudy Nagar, Maleppally Rd<br></br>
            Thrikkakara, Kerala 682021</span></div>
            </div>
    


            </div>

            <div className='foot' style={{display:"flex",flexDirection:"column",alignItems:"center",width:"100%",paddingBottom:"20px",paddingTop:"20px"}}>
                <div>
                    <a href='https://www.instagram.com/agnextech' target="_blank">
                    <IconButton className='flair-badge-wrapper' aria-label="remove"    size="large" >
<InstagramIcon  aria-hidden="true"></InstagramIcon>
</IconButton>
                    </a>

<IconButton className='flair-badge-wrapper' aria-label="remove"    size="large" >

<LinkedInIcon  aria-hidden="true"></LinkedInIcon>
</IconButton>
<IconButton className='flair-badge-wrapper' aria-label="remove"    size="large" >

<FacebookIcon  aria-hidden="true"></FacebookIcon>
</IconButton>
                </div>
                <span style={{fontSize:"14px",color:"#8f949f"}}>©2023 AgenX Projectify, All Rights Reserved.</span>
            </div>
        </div></div>
  )
}

export default Footer