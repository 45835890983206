import firebase from "firebase/compat/app"
import 'firebase/compat/auth';
import 'firebase/compat/firestore'
import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
    apiKey: "AIzaSyA4NO1xna8srSObctMV4IKUfWOaXj31oxs",
    authDomain: "agnex-web.firebaseapp.com",
    projectId: "agnex-web",
    storageBucket: "agnex-web.appspot.com",
    messagingSenderId: "13655787578",
    appId: "1:13655787578:web:829c5d4170816ed6344d03",
    measurementId: "G-BGH9CH733B"
  };

  
  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();


  export default db;

  export const createaccount = async (data) => {
    if(!data) return
    const {contact} =data
    const userRef = db.doc(`contacts/${uuidv4()}`);
     try {
            await userRef.set({
              email: contact.email,
              name: contact.name,
              phn: contact.phn,
              createdAt: new Date(),
            });
          } catch (error) {
            console.log('Error in adding contact', error);
          }
    
  } 