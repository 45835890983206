import React from 'react';
import bgm_animated  from '../assets/bgm4.gif';
import './all.css'


function Landungpage() {



  return (
    <div style={{width:"100%",height:"100vh",position:"relative",backgroundColor:"#000"}}> 
<img  style={{height:"100%",width:"100%",objectFit:"cover",position:"absolute",bottom:"0",right:"0",top:"0"}}  src={bgm_animated}/>

<div style={{height:"100%",width:"100%",objectFit:"cover",position:"absolute",bottom:"0",right:"0",top:"0",backgroundColor:"black",opacity:".9"}}></div>

<div className='un' style={{objectFit:"cover",position:"absolute",left:"8%",bottom:"0",top:"38%",color:"white",display:"flex",flexDirection:"column",alignItems:"start",textAlign:"start"}}>
<span style={{margin:"0",fontWeight:"600",letterSpacing:"1px"}} >UNLOCK <br></br>PROJECT POTENTIAL WITH<br></br> <span style={{color:"#7fdbbb"}}>AGNEX PROJECTIFY</span></span>
<span style={{margin:"0",fontSize:"20px"}}>Simplify, streamline, achieve excellence.</span>
<a  href= "https://forms.gle/oTqCL7orLzFM1V5w8" style={{padding:"5px 10px 5px 10px",backgroundColor:"#7fdbbb",marginTop:"10px",color:"black",borderRadius:"5px",textDecoration:"none"}}>Learn more</a>
</div>


    </div>
  )
}

export default Landungpage