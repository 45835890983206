import React from 'react'
import bgm_animated  from '../assets/bgm10.png';
function Nav() {
  return (
    <div className='nav' >
        <div style={{display:"flex",width:"85%",margin:"auto"}}>
        <img style={{height:"80px"}} src={bgm_animated}></img>
        </div>

    </div>
  )
}

export default Nav