import React from 'react'

function Aboutus() {
  return (
    <div style={{color:"white",width:"100%",height:"max-content",paddingTop:"50px",paddingBottom:"50px"}}>

       <div className='about' style={{width:"85%",margin:"auto",display:"flex",flexDirection:"column",gap:"10px",alignItems:"start",left:"7%",right:"7%"}}>
       <span style={{margin:"0",fontWeight:"600",color:"#7fdbbb",}} >What we do</span> 
       <span style={{fontSize:"20px",textAlign:"start"}}>At Projectify, we believe in empowering businesses to unlock their full project potential. With our innovative tools and collaborative platform, we simplify project management, streamline processes, and fuel success. From planning to execution, we are dedicated to transforming projects into seamless experiences, enabling teams to achieve remarkable results with efficiency and precision. Trust Projectify to drive your projects forward and inspire a new standard of excellence.</span>   

       </div>

    </div>
  )
}

export default Aboutus