import React from 'react'
import TextField from '@mui/material/TextField';
import "./all.css"
import { createaccount } from '../firebase';
import bgm_animated  from '../assets/thumbs-up.gif';


function Contactus() {
    const [contact, setContact] = React.useState({
        name: "",
        email: "",
        phn: ""
      });
    const [success,setSuccess] = React.useState(false)  
const handleChange = React.useCallback((e) => {
        const { name, value } = e.target;
        setContact({ ...contact, [name]: value });
      },[contact])      
    
      const uploadData = ((e) =>{
        e.preventDefault();
        createaccount({contact}).then(()=>{
            setSuccess(true)
            console.log("success")
            setTimeout(()=>{setSuccess(false)},2000)
        })
      
    })


  return (
    <div className='con' style={{height:"max-content",width:"80%",display:"flex",justifyContent:"center",alignItems:"center",margin:"auto",paddingTop:"50px",paddingBottom:"50px"}}>

        <div className='build' style={{textAlign:"left",width:"100%",zIndex:"2000"}}>
        <span style={{margin:"0",fontWeight:"600",color:"white",width:"100%",display:"flex",justifyContent:"center",alignItems:"start",textAlign:"start",flexWrap:"wrap",flexDirection:"column",letterSpacing:"1px"}} >LETS<br></br>
BUILD YOUR<span  style={{color:"#7fdbbb"}}>IDEA</span>
</span>
        </div>


 <div className='box' style={{height:"100%",padding:"10px"}}>
   
   <form  onSubmit={uploadData}>
{!success?
<div className="details  det">

<TextField   name='name'  value={contact.name}
 onChange={handleChange}  required fullWidth id="outlined-basic" label="Name" variant="outlined" sx={{marginTop: '10px',color:"white"}} />
<TextField
 id="outlined-multiline-static"
 fullWidth
 value={contact.email}
 onChange={handleChange}
 required
 name='email'
 label="Email"
 sx={ { marginTop : '10px'}
 }

/>

<TextField
 id="outlined-multiline-static"
 fullWidth
 value={contact.phn}
 onChange={handleChange}
 required
 name='phn'
 label="Mobile-Number"
 sx={ { marginTop : '10px'}
 }


/>
<button type='submit' style={{width:"100%",border:"none",padding:"10px 5px 10px 5px",borderRadius:"5px",marginTop:"10px",fontWeight:"600",backgroundColor:"#7fdbbb",color:"black",cursor:"pointer"}}>SUBMIT</button>
</div>: <img alt='thumpsup' style={{height:"150px"}} src={bgm_animated}/>}
</form>

   </div>
        </div>

  )
}

export default Contactus